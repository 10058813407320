.container {
  width: 100%;
  min-height: 100vh;
}

.content-layout {
  padding: 0 20px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 80px;
  border-radius: 4px;
  background-color: #fff;
  gap: 20px;
}

.hierarchy-menu {
  margin: 16px 0;
}
